<template>
  <div>
    <div>
      <md-button class="md-primary" @click="toggleAll">
        {{ allCollapsed ? 'Expandir todos' : 'Colapsar todos' }}
      </md-button>
    </div>
    <div
      v-for="(item, index) in collapse"
      :key="index"
      :class="[
        'md-collapse',
        { 'is-active': isActive[index] },
        { [getColorCollapse(colorCollapse)]: true },
      ]"
    >
      <div class="md-collapse-label" @click="toggle(index)">
        <h5 class="md-collapse-title">
          {{ item }}
          <md-icon>{{ isActive[index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</md-icon>
        </h5>
      </div>
      <collapse-transition>
        <div class="md-collapse-content" v-show="isActive[index]">
          <slot :name="getCollapseContent(index)"></slot>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "collapse",
  components: {
    CollapseTransition,
  },
  props: {
    collapse: Array,
    colorCollapse: String,
  },
  data() {
    return {
      isActive: [],
      allCollapsed: false,
    };
  },
  created() {
    // Inicializar el array isActive con false para cada elemento
    this.isActive = new Array(this.collapse.length).fill(false);
  },
  methods: {
    toggleAll() {
      this.allCollapsed = !this.allCollapsed;
      this.isActive = this.isActive.map(() => !this.allCollapsed);
    },
    toggle(index) {
      this.$set(this.isActive, index, !this.isActive[index]);
      this.updateAllCollapsedState();
    },
    getCollapseContent(index) {
      return `md-collapse-pane-${index + 1}`;
    },
    getColorCollapse(colorCollapse) {
      return `md-${colorCollapse}`;
    },
    updateAllCollapsedState() {
      this.allCollapsed = this.isActive.every(status => !status);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>