<template>
    <div class="fixed-plugin" v-click-outside="closeDropDown">
      <div class="dropdown show-dropdown" :class="{ show: isOpen }">
        <a data-toggle="dropdown">
          <i class="fa fa-phone fa-2x" @click="toggleDropDown"> </i>
        </a>
        <ul class="dropdown-menu" :class="{ show: isOpen }">
          <li class="phoneStyle"><Chat/></li>
        </ul>
      </div>
    </div>
  </template>
  <script>
  import Chat from "@/paginas/Chat/index.vue";
  export default {
    components: {
        Chat
    },
    data() {
      return {
        isOpen: false,  
      };
    },
    methods: {
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      }
    }
  };
  </script>
  <style>
  .phoneStyle {
    margin-left: -50px;
  }
  </style>
  