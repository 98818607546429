<template>
  <div class="attachments-container">
    <md-button class="md-primary" @click="updateAttachments">Actualizar</md-button>
    <div class="attachments-list">
      <div v-for="attachment in adjuntos" :key="attachment.id" class="attachment-item">
        <h4 class="attachment-title">{{ attachment.nombreArchivo }}</h4>
        <div class="md-layout-item md-size-100 md-medium-size-100">
          <md-radio v-model="attachment.esValido" value="true">Sí</md-radio>
          <md-radio v-model="attachment.esValido" value="false">No</md-radio>
        </div>
        <md-field>
          <label>Tipo de Documento</label>
          <md-select v-model="attachment.tipoDocumentoId">
            <md-option v-for="a in typeDocuments" :key="a.tipoDocumentoId" :value="a.tipoDocumentoId">
              {{ a.tipoDocumentoDesc }}
            </md-option>
          </md-select>
        </md-field>
        <iframe class="attachment-frame" :src="getAttachmentUrl(attachment)" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import servAdjuntos from "@/Servicios/Correo/Adjuntos";
export default {
  name: 'ListAttachments',
  props: {
    adjuntos: {
      type: Array,
      required: true,
      default: () => [],
    },
    typeDocuments: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      baseURLS3: process.env.VUE_APP_BASE_URL_BUCKET || '',
      officeViewerUrl: 'https://view.officeapps.live.com/op/embed.aspx?src=',
    };
  },
  methods: {
    updateAttachments() {         
      console.log('Adjuntos:', this.adjuntos);   
      servAdjuntos.update(this.adjuntos)
        .then(() => {
          this.$notifyVue('success', 'Adjuntos actualizados correctamente');
          this.$emit('update-adjuntos', this.adjuntos);
        })
        .catch((error) => {
          console.error('Error al actualizar adjuntos:', error);
          this.$notifyVue('error', 'Error al actualizar');
        });
    },
    getAttachmentUrl(attachment) {
      const fileUrl = this.baseURLS3 + attachment.claveS3;
      if (this.isOfficeFile(attachment.nombreArchivo)) {
        return `${this.officeViewerUrl}${encodeURIComponent(fileUrl)}`;
      }
      return fileUrl;
    },
    isOfficeFile(fileName) {
      const officeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
      return officeExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    },
    show() {
    },
  }
};
</script>

<style scoped>
.attachments-container {
  flex: 1;
  background-color: white;
  border-radius: 15px;
  border: 1px solid grey;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.attachments-list {
  flex: 1;
  overflow-y: auto;
  margin: 10px 0;
}

.attachment-item {
  margin-bottom: 20px;
}

.attachment-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: black;
  margin-bottom: 15px;
}

.attachment-frame {
  width: 100%;
  height: 600px;
  border: none;
}

.md-button {
  align-self: flex-end;
}
</style>