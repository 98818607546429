// notifyPlugin.js
export default {
  install(Vue) {
    Vue.prototype.$notifyVue = function(tipo, mensaje) {
      this.$notify({
        message: mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: tipo,
      });
    };
  }
};