import { render, staticRenderFns } from "./listAttachments.vue?vue&type=template&id=447a5352&scoped=true"
import script from "./listAttachments.vue?vue&type=script&lang=js"
export * from "./listAttachments.vue?vue&type=script&lang=js"
import style0 from "./listAttachments.vue?vue&type=style&index=0&id=447a5352&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447a5352",
  null
  
)

export default component.exports