<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Chat Room</div>
        <button class="md-icon-button" @click="connectWebSocket">
          <md-icon>refresh</md-icon>
        </button>
      </md-card-header>
      <md-card-content>
        <div v-for="(message, index) in messages" :key="index" 
             :class="['chat-message', message.sender === 'Tu' ? 'from-you' : 'from-others']">
          <strong>{{ message.sender }}:</strong> {{ message.text }}
        </div>
      </md-card-content>
      <md-card-actions>
        <md-field>
          <label>Message</label>
          <md-input v-model="newMessage" @keyup.enter="sendMessage"></md-input>
        </md-field>
        <md-button class="md-raised md-primary" @click="sendMessage">Send</md-button>
      </md-card-actions>
    </md-card>
  
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMessage: '',
      messages: [],
      socket: null
    };
  },
  mounted() {
    //this.connectWebSocket();
    if (this.socket) {
      this.socket.close();
    }
  },
  beforeDestroy() {
    // Cierra la conexión WebSocket al destruir el componente
    if (this.socket) {
      this.socket.close();
    }
  },
  methods: {
    connectWebSocket() {
      this.socket = '';//new WebSocket('wss://localhost:7085/ws');

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = (event) => {
        console.log('WebSocket message received:', event);    
        const message = {
          sender: 'Bot',
          text: event.data
        };    
        this.messages.push(message);
      };

      this.socket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event.code} ${event.reason}`);
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const message = {
          sender: 'You',
          text: this.newMessage
        };
        this.socket.send(JSON.stringify(message));
        this.messages.push(message);
        this.newMessage = '';
      }
    }
  }
};
</script>
<style>
.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 60%;
}

.from-you {
  background-color: #e0f7fa;
  text-align: left;
  margin-right: auto;
}

.from-others {
  background-color: #f1f8e9;
  text-align: right;
  margin-left: auto;
}
</style>