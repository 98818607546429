<template>
  <div>
    <md-card>
      <md-ripple>
        <md-card-header>
          <div class="md-title">
            {{ "De: " + emailObj.nombreRemitente }}
          </div>
          <div class="md-subhead">
            {{ "Asunto: " + emailObj.asunto + " Fecha: " + emailObj.fecha }}
          </div>
        </md-card-header>
        <md-card-content>
          <div v-if="emailObj.isHtml" v-html="sanitizedHTML"></div>
          <div v-else class="md-subhead">{{ emailObj.cuerpo }}</div>
        </md-card-content>
        <md-card-actions>
          <!-- <div v-for="chip in emailObj.adjuntos" :key="chip.id">
            <md-button class="md-primary" @click="viewAttachment(chip)"><md-icon>visibility</md-icon>{{
              chip.nombreArchivo }}</md-button>
          </div> -->
        </md-card-actions>
      </md-ripple>
    </md-card>
    <md-dialog :md-active.sync="showAttachmentDialog">
      <md-dialog-title>{{ currentAttachment.nombreArchivo }}</md-dialog-title>
      <md-dialog-content>
        <img v-if="isImage" :src="baseURLS3 + currentAttachment.claveS3" alt="Attachment" style="max-width: 100%;">
        <iframe v-else :src="baseURLS3 + currentAttachment.claveS3" style="width: 100%; height: 500px;"></iframe>
      </md-dialog-content>
      <md-dialog-actions>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import DOMPurify from "dompurify";

export default {
  props: {
    emailObj: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      showAttachmentDialog: false,
      currentAttachment: {},
      isImage: false,
      baseURLS3: process.env.VUE_APP_BASE_URL_BUCKET,
    };
  },
  computed: {
    sanitizedHTML() {
      return DOMPurify.sanitize(this.emailObj.body);
    }
  },
  methods: {
    viewAttachment(attachment) {
      this.currentAttachment = attachment;
      this.isImage = this.currentAttachment.tipoContenido.startsWith('image/');
      this.showAttachmentDialog = true;
    },
  },
};
</script>