import { http } from "@/http-commons";
import { httpDonwload } from "@/http-commons";

class DocumentoPlantilla {
  getId(id) {
    return http.get(`/Documentos/${id}`);
  }
  all() {
    return http.get(`/DocumentoPlantilla`);
  }
  getContrato(FisicaMoral, ContratoId, UsuarioID) {
    return http.get(
      `/GenerarContrato/${FisicaMoral}/${ContratoId}/${UsuarioID}`
    );
  }
  getObservaciones(observacionesId){
    return http.get(`/Observaciones/${observacionesId}`);
  }
}

export default new DocumentoPlantilla();
