<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-35">
      <listEmail v-bind:tableData="dataTable" @emailSelected="handleEmailSelected" />
    </div>
    <div class="md-layout-item md-size-65">
      <cardEmail v-bind:emailObj="emailSelected" />
      <div v-if="emailSelected && emailSelected.adjuntos">
        <listAttachments 
        v-bind:adjuntos="emailSelected.adjuntos || []" 
        v-bind:typeDocuments="typeDocumentsData"
        @update-adjuntos="handleUpdateAdjuntos"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListEmail from './Componentes/listEmail.vue'
import cardEmail from './Componentes/cardEmail.vue'
import servCorreo from "@/Servicios/Correo/Correo"
import listAttachments from './Componentes/listAttachments.vue';
import servTipo from "@/Servicios/Catalogos/tipodocumento";
export default {
  components: {
    ListEmail,
    cardEmail,
    listAttachments
  },
  data() {
    return {
      emailSelected: null,
      dataTable: [],
      typeDocumentsData: []
    };
  },
  computed: {
  },
  methods: {
    getDocType(){
      servTipo.all()
      .then((r) => {
        this.typeDocumentsData = r.data;
      })
    },
    getDatos() {
      servCorreo.all().then((res) => {        
        this.dataTable = res.data;
      });
    },
    handleEmailSelected(email) {
      this.emailSelected = email;
    },
    handleUpdateAdjuntos(updatedAdjuntos) {
      if (this.emailSelected) {
        this.emailSelected.adjuntos = updatedAdjuntos;
      }
    }
  },
  created() {
    this.getDatos();
    this.getDocType();
  }
}
</script>

<style scoped>
</style>