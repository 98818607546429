<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Correos</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover" @md-selected="onSelect">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
              <md-table-cell md-label="Id" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Remitente" md-sort-by="fromName">{{ item.nombreRemitente }}</md-table-cell>
              <md-table-cell md-label="Correo" md-sort-by="fromAddress">
                <div class="multi-line-cell">
                  {{ item.direccionRemitente }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Asunto" md-sort-by="subject">{{ item.asunto }}</md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="date">{{ item.date }}</md-table-cell>
              <md-table-cell md-label="Archivos" md-sort-by="attachments">{{ item.adjuntos.length }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";

export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Pagination,
  },
  computed: {
    queriedData() {
      let result = this.tableData.map(item => ({ ...item, selected: false }));
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    hasSelection() {
      return this.queriedData.some(item => item.selected);
    },
  },
  data() {
    return {
      currentSort: "date",
      currentSortOrder: "desc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return b[sortBy].localeCompare(a[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy]);
      });
    },
    onSelect(itemSelected) {
      if (itemSelected) {
        this.$emit('emailSelected', itemSelected);
      }
    },
  },
  watch: {
    searchQuery(value) {
      if (!this.fuseSearch) {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["nombreRemitente", "direccionRemitente", "asunto"],
          threshold: 0.3,
        });
      }
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(value).map(r => r.item);
      }
      this.searchedData = result;
    },
    tableData: {
      immediate: true,
      handler(value) {
        this.fuseSearch = new Fuse(value, {
          keys: ["nombreRemitente", "direccionRemitente", "asunto"],
          threshold: 0.3,
        });
      }
    }
  },
};
</script>
<style scoped>
.multi-line-cell {
  white-space: pre-line;
  /* Permite saltos de línea */
  word-break: break-word;
  /* Permite que las palabras se dividan si son demasiado largas */
}
</style>