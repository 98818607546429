//import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import DashboardLayout from "@/paginas/Layout/dashboard.vue";
import AuthLayout from "@/paginas/Layout/AuthLayout.vue";
import ejemplo from "@/pages/Dashboard/Forms/ExtendedForms.vue";


// Dashboard pages
import DashboardAdmin from "@/paginas/Dashboard/admin.vue";
import DashboardRepresentacion from "@/paginas/Dashboard/representante.vue";
import DashboardGenerico from "@/paginas/Dashboard/generico.vue";

// Pages
import Login from "@/paginas/seguridad/Login.vue";

//General
import listadoPolizas from "@/paginas/General/Polizas/listado.vue";
import tabsPoliza from "@/paginas/General/Polizas/tabs.vue";
import buscar from "@/paginas/General/Buscar.vue";

//Administracion
import cxp from "@/paginas/Administracion/CuentasXPagar/crud.vue";
import CuentasBancarias from "@/paginas/Administracion/CuentasBancarias/crud.vue";
import categorias from "@/paginas/Administracion/Categorias/crud.vue";

//Seguridad
import usuarios from "@/paginas/Administracion/seguridad/UsuariosCrud.vue";
import log from "@/paginas/seguridad/log.vue";
import menup from "@/paginas/seguridad/Menu.vue";
import prueba from "@/paginas/Administracion/seguridad/prueba.vue";

// Solicitudes
import solicitud from "@/paginas/Solicitudes/Solicitudes.vue";
import createUpdateSolicitud from "@/paginas/Solicitudes/CreateUpdate.vue";
import FlujoSolicitud from "@/paginas/Solicitudes/FlujoSolicitud.vue";

//Catalogos
import areas from "@/paginas/Catalogos/areas.vue";
import parametros from "@/paginas/Administracion/Parametros/crud.vue";
import centrocostos from "@/paginas/Catalogos/centrocostos.vue";
import tipodocumento from "@/paginas/Catalogos/tipodocumento.vue";
import estados from "@/paginas/Catalogos/estados.vue"
import tipoparentesco from "@/paginas/Catalogos/tipoparentesco.vue"
import tipoinmobiliario from "@/paginas/Catalogos/tipoinmobiliario.vue"
import tipoproceso from "@/paginas/Catalogos/tipoproceso.vue";
import tiporefPersonales from "@/paginas/Catalogos/tiporefpersonal.vue";
import confEmail from "@/paginas/Catalogos/confEmail.vue";
import ConfEmailXUsuario from "@/paginas/Catalogos/confEmailXUsuario.vue";
import TipoReferenciaComercial from "@/paginas/Catalogos/TipoReferenciaComercial.vue";
import estatussoluciones from "@/paginas/Catalogos/estatussoluciones.vue";
import estatuspoliza from "@/paginas/Catalogos/estatuspoliza.vue";
import PersonasFisicasPrueba from "@/paginas/Catalogos/PersonasFisicasPrueba.vue";
import CorreosRestringidos from "@/paginas/Catalogos/correosRestringidos.vue";
import estatusCalendario from "@/paginas/Catalogos/estatusCalendario.vue";


//Módulo Contable
import movimientos from "@/paginas/Administracion/Movimientos/listado.vue";
import movimientoscrud from "@/paginas/Administracion/Movimientos/crud.vue";
// import ejemplo from "@/pages/Dashboard/Tables/PaginatedTables.vue";

//Representaciones
import crudEventos from "@/paginas/Representaciones/eventos.vue";
import representaciones from "@/paginas/Catalogos/representaciones.vue";
import crudlistasnegras from "@/paginas/Representaciones/listasnegras.vue";
import crudclienteusuarios from "@/paginas/Representaciones/clienteusuarios.vue";
import UsuariosPorEvento from "@/paginas/Representaciones/UsuariosPorEvento.vue";
import HistoricoEmails from "@/paginas/Representaciones/HistoricoEmails.vue";

//Reportes 
import reporteventasgerencia from "@/paginas/Reportes/ventas.vue";

//Administración
import ConfCuentaRep from "@/paginas/Administracion/confcuentasrep/crud.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";

//Settings User
import User from "@/paginas/User/UserProfile.vue";

//Soluciones
import solucionesIndex from "@/paginas/Soluciones/Index.vue";
import tabssoluciones from "@/paginas/Soluciones/Solucion/tabs.vue";
import dashboardsoluciones from "@/paginas/Dashboard/soluciones.vue";
import editsoluciones from "@/paginas/Soluciones/Solucion/tabs.vue";  //usa esta para para pagna de seguimiento 
import nuevasolucion from "@/paginas/Soluciones/Solucion/Nueva.vue";
import createUpdate from "@/paginas/Soluciones/Solucion/createupdate.vue";

//No found 
import Nofound from "@/paginas/Nofound.vue";

import Operacion from "@/paginas/Operacion/Index.vue";
import Editar from "@/paginas/Operacion/Index.vue";

//email
import email from "@/paginas/Email/Index.vue";

//Contratos
import keywordsCompuestas from "@/paginas/Contratos/keywordsCompuestas.vue";
import EstructuraKeywords from "@/paginas/Contratos/EstructuraKeyword.vue";
import DocumentosPlantilla from "@/paginas/Contratos/DocumentosPlantilla.vue";


//Pantalla de pruebas
import cargaMasivaPolizas from "@/paginas/Apoyo/CargaMasivaPolizas.vue"
import Chat from "@/paginas/Chat/index.vue";


//Agenda 
import Agenda from "@/paginas/Agenda/index.vue";

//Correo
import Emails from "@/paginas/Email/Index.vue";
//Phone
import Phone from "@/paginas/Phone/index.vue";
//Kanban
import Kanban from "@/paginas/Kanban/index.vue";


let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [{
        path: "/login",
        name: "Login",
        component: Login
    }]
};

let operacionPages = {
    path: "/",
    component: DashboardLayout,
    name: "Operaciones",
    children: [{
        path: "/operacion",
        name: "operacion",
        component: Operacion
    }, {
        path: "/editar",
        name: "editar",
        component: Editar
    }
    ]
};


let pruebasPage = {
    path: "/",
    component: DashboardLayout,
    name: "Pruebas",
    children: [{
        path: "/cargaMasivaPolizas",
        name: "cargaMasivaPolizas",
        component: cargaMasivaPolizas
    }]
};

let agendaPage = {
    path: "/",
    component: DashboardLayout,
    name: "agendaComponent",
    children: [{
        path: "/Agenda",
        name: "agenda",
        component: Agenda
    }]
};

let authsolucionesPages = {
    path: "/",
    component: DashboardLayout,
    name: "Soluciones",
    children: [{
        path: "/soluciones",
        name: "soluciones",
        component: solucionesIndex
    }, {
        path: "/tabssoluciones",
        name: "tabssoluciones",
        component: tabssoluciones
    },
    {
        path: "/solucionesDashboard",
        name: "solucionesDashboard",
        component: dashboardsoluciones
    },
    {
        path: "/EditarSolucion",
        name: "EditarSolucion",
        component: editsoluciones
    },
    {
        path: "/nuevasolucion",
        name: "nuevasolucion",
        component: nuevasolucion
    },
    {
        path: "/createUpdateSolucion",
        name: "createUpdateSolucion",
        component: createUpdate
    },
    ]
};

let administracionpages = {
    path: "/",
    component: DashboardLayout,
    name: "administacion",
    children: [
        {
            path: "/ConfCuentaRep",
            name: "ConfCuentaRepr",
            component: ConfCuentaRep
        }, {
            path: "/cuentasxpagars",
            name: "CuentasXpagar",
            component: cxp
        },
        {
            path: "/movimientos",
            name: "Movimientos",
            component: movimientos
        },
        {
            path: "/movimientos/crud/:id",
            name: "movimientoscrud",
            component: movimientoscrud
        },
        {
            path: "/parametros",
            name: "parametros",
            component: parametros
        },
        {
            path: "/CuentasBancarias",
            name: "CuentasBancarias",
            component: CuentasBancarias
        },
        {
            path: "/categoriaes",
            name: "categoriaes",
            component: categorias
        }]
};

let representacionespages = {
    path: "/",
    component: DashboardLayout,
    // name: "representaciones",
    children: [{
        path: "/eventos",
        name: "eventos",
        component: crudEventos
    },
    {
        path: "/listanegras",
        name: "listasnegras",
        component: crudlistasnegras
    },
    {
        path: "/clienteusuarios",
        name: "clienteusuarios",
        component: crudclienteusuarios
    },
    {
        path: "calendarios",
        name: "Calendar",
        components: { default: Calendar }
    },
    {
        path: "eventousuarios",
        name: "eventousuarios",
        components: { default: UsuariosPorEvento }
    }, {
        path: "historicoEmails",
        name: "historicoEmails",
        components: { default: HistoricoEmails }
    }]
};

let reportes = {
    path: "/",
    component: DashboardLayout,
    name: "reportes",
    children: [{
        path: "/reportes",
        name: "reporteventasgerencia",
        component: reporteventasgerencia
    }]
};

let seguridad = {
    path: "/",
    component: DashboardLayout,
    name: "seguridad",
    children: [{
        path: "/Logs",
        name: "Logs",
        component: log
    }, {
        path: "usuarios",
        name: "usuarios",
        components: { default: usuarios }
    },
    {
        path: "prueba",
        name: "prueba",
        components: { default: prueba }
    },
    {
        path: "/Menups",
        name: "Menups",
        component: menup
    }]
};

let componentes = {
    path: "/",
    component: DashboardLayout,
    name: "componentesEmail",
    children: [{
        path: "/email",
        name: "email",
        component: email
    }]
};

let contratos = {
    path: "/",
    component: DashboardLayout,
    name: "contratos",
    children: [{
        path: "/KeywordStructuras",
        name: "KeywordStructuras",
        component: EstructuraKeywords,
    },
    {
        path: "/DocumentoPlantillas",
        name: "documentoplantilla",
        component: DocumentosPlantilla
    },
        // {
        //     path: "/KeywordStructuras",
        //     name: "KeywordStructuras",
        //     component: keywordsCompuestas
        // }
    ]
};
let catalogos = {
    path: "/",
    component: DashboardLayout,
    name: "catalogos",
    children: [{
        path: "TipoProcesoes",
        name: "TipoProcesoes",
        component: tipoproceso
    }, {
        path: "/tipoinmobiliarios",
        name: "tipoinmobiliario",
        component: tipoinmobiliario
    },
    {
        path: "/tipoparentescoes",
        name: "tipoparentesco",
        component: tipoparentesco
    },
    {
        path: "/TipoRefPersonals",
        name: "TipoRefPersonals",
        component: tiporefPersonales
    },
    {
        path: "/TipoRefComercials",
        name: "TipoRefComercials",
        component: TipoReferenciaComercial
    },
    {
        path: "/procesosoluciones",
        name: "procesosoluciones",
        component: estatussoluciones
    },
    {
        path: "/TipoProcesoPoes", // link con que aparece en sistema. 
        name: "TipoProcesoPoes",
        component: estatuspoliza
    },
    {
        path: "/PersonasFisicasPrueba", // link con que aparece en sistema. 
        name: "PersonasFisicasPruebas",
        component: PersonasFisicasPrueba
    },
    {
        path: "/Emails", // link con que aparece en sistema. 
        name: "Emails",
        component: Emails
    },
    {
        path: "/CorreosRestringidos", // link con que aparece en sistema. 
        name: "CorreosRestringidos",
        component: CorreosRestringidos
    },
    {
        path: "/estatusCalendario", // link con que aparece en sistema. 
        name: "estatusCalendario",
        component: estatusCalendario
    },
    {
        path: "/Kanban", // link con que aparece en sistema. 
        name: "Kanban",
        component: Kanban
    },
    {
        path: "/chat", // link con que aparece en sistema. 
        name: "chat",
        component: Chat
    }
    ]
};



const routes = [{
    path: "/",
    redirect: "/login",
    name: "login"
},
    agendaPage,
    authPages,
    administracionpages,
    representacionespages,
    reportes,
    catalogos,
    contratos,
    authsolucionesPages,
    seguridad,
    operacionPages,
    componentes,
   
{
    path: "/",
    component: DashboardLayout,
    children: [{
        path: "admin",
        name: "admin",
        components: { default: DashboardAdmin }
    },
    {
        path: "representate",
        name: "representate",
        components: { default: DashboardRepresentacion }
    },
    {
        path: "generico",
        name: "generico",
        components: { default: DashboardGenerico }
    },
    {
        path: "polizas",
        name: "polizas",
        components: { default: listadoPolizas }
    },
    {
        path: "tabspoliza",
        name: "tabspoliza",
        components: { default: tabsPoliza }
    },
    {
        path: "buscar",
        name: "buscar",
        components: { default: buscar }
    },
    {
        path: "areas",
        name: "areas",
        components: { default: areas }
    },
    {
        path: "ConfEmail",
        name: "ConfEmail",
        components: { default: confEmail }
    },
    {
        path: "ConfEmailXUsuario",
        name: "ConfEmailXUsuario",
        components: { default: ConfEmailXUsuario }
    },
    {
        path: "centrocostos",
        name: "centrocostos",
        components: { default: centrocostos }
    },
    {
        path: "representacions",
        name: "representaciones",
        components: { default: representaciones }
    },
    {
        path: "ejemplo",
        name: "ejemplo",
        components: { default: ejemplo }
    },
    {
        path: "phone",
        name: "phone",
        components: { default: Phone }
    },
    {
        path: "estados",
        name: "estados",
        components: { default: estados }
    },
    {

        path: "/",
        component: DashboardLayout,
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                components: { default: DashboardAdmin }
            },
            {
                path: "tipodocumentoes",
                name: "tipodocumento",
                components: { default: tipodocumento }
            },
        ]
    },
    {

        path: "Solicituds", /// ENTRADA SOLICITUD///
        name: "Solicitudes",
        components: { default: solicitud }
    },
    {
        path: "createUpdateSolicitud",
        name: "createUpdateSolicitud",
        components: { default: createUpdateSolicitud }
    },
    {
        path: "solicitud/FlujoSolicitud",
        name: "FlujoSolicitud",
        components: { default: FlujoSolicitud }

    },
    {
        path: "User",
        name: "User",
        components: { default: User }
    },

    ]
}
];
export default routes;