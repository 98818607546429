<template>
  <div style="display: flex; width: 100%; height: 750px; margin-bottom: 10px" >
    <div  style="flex: .4; height: 100%;">
      <!---
        TARJETA DE DOCUMENTOS CON EL CONTENIDO DE LOS DOCUMENTOS Y EL BOTON NUEVO
        ---->
      <md-card style=" height: 90%; width: 90%;">
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Documentos</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary md-just-icon md-round" @click="getDatos()"
                  ><md-icon >cached</md-icon></md-button
                >
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row style="text-align: center" slot="md-table-row" slot-scope="{ item }">
              <md-table-cell style="text-align: center" 
                md-label="Descripción"
                md-sort-by="documentoDesc"
                >{{ item.documentoDesc }}</md-table-cell>
                <md-table-cell md-label="Acciones" style="width: 100px">
                  <md-button
                 class="md-just-icon md-danger md-simple"
                 @click.native="ModalPersonalizado(2, item)"
                >
                   <md-icon class="md-primary">download</md-icon>
                </md-button>
                <md-button
                  
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    <!--
      MODAL PARA INGRESAR NUEVO DOCUMENTO
      ---->
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="documentosId" disabled></md-input>
            <span class="md-helper-text">Id</span> </md-field
          ><md-field>
            <label>Descripción</label>
            <md-input v-model="documentoDesc"></md-input>
            <span class="md-helper-text">descripción</span>
          </md-field>
          <md-field>
            <label v-if="!isBandera" required>Tipo de Documento</label>
            <label style="color: brown !important;" v-if="isBandera" required>Tipo de Documento</label>
            <md-select v-model="tipoDocumentoId">
              <md-option
                v-for="a in lista"
                :key="a.tipoDocumentoId"
                :value="a.tipoDocumentoId"
                >
                  {{ a.tipoDocumentoDesc }}     
              </md-option>
            </md-select>
          </md-field>
          <div style="margin-top: 10px;">
          <!-----
            BOTON PARA SELECCIONAR DOCUMENTOS DESDE EL EXPLORADOR DE ARCHIVOS
            ---->
            <p style=" color: black" v-if="!isBandera" required>Documento: </p>
            <p style="color: brown !important;" v-if="isBandera" required>Documento:</p>
            <p v-if="fileName != null"> {{ fileName }}</p>
            <md-button class="md-success" @click.native="select()">Seleccionar Archivo</md-button>
            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload">
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            v-if="this.visible == false"
            class="md-primary"
            @click.native="postNow(1)"
            >{{ datosModal.boton }}
          </md-button>
          <md-button v-if="this.visible == true" class="md-primary" disabled>
            <div class="loader"></div>
          </md-button>
        </template>
      </modal>
    </div>
      <!---
           CONTENEDOR PARA VISUALIZAR ARCHIVOS MEDIANTE IFRAME  
          ---->
    <div style="flex: 1; background-color: white; border-radius: 15px; border: 1px solid grey;">
      <div style="height: 90%; width: 90%; overflow-y: auto;">
        <div v-for="a in this.listaVal" :key="a.documentosId" :value="a.documentosId" style="width: calc(100% - 100px); height: 750px; margin: 10px auto; ">
        <div style="margin-bottom: 15px; margin-top: 25px;">
        <h4  style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; color: black">{{ a.documentoDesc }}</h4>
        <iframe class="frame" :src="a.documentosImagen" frameborder="0">
         </iframe>
      </div>        
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servDoc from "@/Servicios/Operacion/documentos";
import store from "@/Store/index";
import servTipo from "@/Servicios/Catalogos/tipodocumento";
import servS3  from "@/Utilerias/serviceStorage";
export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "areaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Descripción", "Dashboard", "Acciones"],
      searchQuery: "",
      propsToSearch: ["areaId", "areaDescripcion", "dashboard"],
      tableData: [],
      searchedData: [],
      lista : [],
      listaVal : [],
      fuseSearch: null,
      classicModal: false,
      documentosId: 0,
      tipoDocumentoId: 0,
      fisicaMoralId: 0,
      documentosImagen: "",
      documentoDesc : "",
      fileName :"",
      file: null,
      datosModal: {
        titulo: "Documentos",
        boton: "Confirmar",
        evento: 0,
      },
      visible : false,
      isBandera: false,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    //OBTENEMOS EL TIPO DE DOCUMENTO
    getDocType(){
      servTipo.all()
      .then((r) => {
        this.lista = r.data;
      })
    },
    // OBTENEMOS LAS VALIDACIONES PARA VISUALIZAR LOS DOCUMENTOS
    getVisualization(){
      servDoc.getVisual(this.general.FisicaMoralId)
      .then((r) => {
          this.listaVal = r.data;
          console.log(this.listaVal);
          console.log(this.general.FisicaMoralId);
      });
    },
    //FUNCION PARFA ABRIR EL EXPLORADOR DE ARCHIVOS
    select(){
      this.$refs.fileInput.click();
    },
    //LOGICA PARA LEER EL ARCHIVO SELECCIONADO Y SUS CARACTERISTICAS COMO EL NOMBRE Y EL TIPOD DE ARCHIVO
    handleFileUpload(event){
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = ()  =>{
        this.documentosImagen = reader.result;
      };
      this.fileName = this.file.name;
      reader.readAsDataURL(this.file);
    },
    //FUNCION PARA CARGAR LOS ARCHIVOS A LA RUTA DEL S3 DE AMAZON AWS
     async uploadS3(){
      if(this.file != null){
        let name = this.file.name.replace(/\s/g, "");
        console.log(this.name);
        let pathS3 = this.general.SolicitudId + "/"  + name;
        return await servS3.upload(pathS3, this.file, this.file.type)
        .then((r) =>{
           return r.Location;
        })
        .catch(function(e){
          mensaje.Error("crud-post-servStorage", e)
          return null
        })
      }
      else {
        this.visible = false;
        this.isBandera = true;
        let m = {
          mensaje: "Selecciona un archivo",
          tipo: "",
        };
        m.tipo = "danger";
        this.notifyVue(m);
      }
      
    },
    //FUNCION PARA HACER EL INSERT EN LA BASE DE DATOS CON LA RUTA DEL S3 Y LOS DEMAS ATRIBUTOS
    async postNow(exp) {
      this.visible = true;
      let pathS3 = await this.uploadS3();
      let nameFile = this.file.name.replace(/\s/g, "");
      var data = {
        DocumentosId: this.documentosId,
        TipoDocumentoId :this.tipoDocumentoId,
        FisicaMoralId :this.general.FisicaMoralId,
        DocumentosImagen : pathS3,
        DocumentoDesc: nameFile,
      };
      switch (exp) {
        case 1:
          servDoc
            .post(data)
            .then((r) => {
               this.ProcesamosResponse(r.data)
            })
            .catch(function (e) {
              mensaje.Error("area-crud-post", e);
            });
          break;
      }
    },
    //FUNCION PARA OBTENER LOS DATOS DE LA TABLA DOCUMENTOS FILTRANDO POR FISICAMORALID
    getDatos() {
      console.log(this.general.FisicaMoralId);
      servDoc
        .getId(this.general.FisicaMoralId)
        .then((a) => {
          this.tableData = a.data;
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["areaId", "areaDescripcion", "dashboard"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });
    },

    //FUNCION PARA CERRAR EL MODAL
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    //SWITCH PARA DEFINIR SI SE VA A HACER UN INSERT, UPDATE O DELETE
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Cargar Documento";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Descargar
          this.documentosId = row.documentosId;
          this.documentoDesc = row.documentoDesc;
          this.download(this.documentoDesc, this.documentosId);
          break;
        case 3: //Eliminar
          this.documentosId = row.documentosId;
          this.deleteRow();
          break;
      }
    },
    //FUNCION PARA DESCARGAR EL DOCUMENTO 
    download(documentoDesc, documentosId){
      servDoc.getDownload(documentosId)
      .then((r) => {
        const url = window.URL.createObjectURL(new Blob ([r.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentoDesc);
        document.body.appendChild(link);
        link.click();
        this.Clear();
      })
    },
    //FUNCION PARA ELIMINAR UN ELEMENTO DE LA TABLA
    deleteRow() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        buttonsStyling: false,
      });
      if (this.documentosId > 0) {
        swalWithBootstrapButtons.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.documentosId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servDoc
                .delete(this.documentosId)
                .then((response) => {
                  swalWithBootstrapButtons.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.documentosId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });                              
                    this.ProcesamosResponse(response.data);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
              }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    //FUNCION PARA VALIDAR LA RESPUESTA DEL SERVIDOR, Y AGREGAR LOGICA DEPENDIENDO DE LA RESPUESTA
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera=true;
            this.visible = false;
            break;
          case 3: //success
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.getDatos();
            this.getVisualization();
            this.visible = false;
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    //VACIAR LOS INPUTS
    Clear() {
      this.documentosId = 0;
      this.documentoDesc = "";
      this.fileName = "";
      this.documentosImagen = "";
      this.tipoDocumentoId = 0;
    },
  },
  //HOOK O CICLO DE VIDA DEL SOFTWARE PARA LLAMAR A TODAS LAS FUNCIONES CUANDO SE CARGUE LA PAGINA
  created() {
    console.log(this.general.FisicaMoralId);
    if(this.general.FisicaMoralId > 0){
      this.getDatos();
      this.getVisualization();
    }
    this.getDocType();
  },
  mounted() {    
    console.log(this.general.FisicaMoralId);
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    //WATCHER PARA VER SI ALGO DENTRO DEL MODULO O COMPONENTE CAMBIA, EN ESTE CASO LA BARRA DE BUSQUEDA
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
.frame{
  width: 70%; height: 700px; display: block; margin: 10px auto;
}
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Guardando....."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}
</style>
