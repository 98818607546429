<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Usuarios</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
              <md-field>
                <label for="FiltroAreaId">(Seleccionar área para filtrar)</label>
                <md-select v-model="FiltroAreaId">
                  <md-option v-for="a in Areas" :key="a.areaId" :value="a.areaId">
                    {{ a.areaDescripcion }}
                  </md-option>
                </md-select>
              </md-field>
              <md-button class="md-success md-just-icon md-round"
                @click="getDatos()"><md-icon>cached</md-icon></md-button>
              <md-button class="md-primary" @click="ModalPersonalizado(1)">Nuevo</md-button>
            </md-table-toolbar>
            <md-progress-bar class="md-warning" md-mode="buffer" v-if="isLoader == true"></md-progress-bar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="usuariosId" style="width: 50px" md-numeric>{{
                item.usuariosId
                }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="usurioEmail" :disabled="datosModal.evento == 2">
                <div class="multi-line-cell">
                  {{ item.usurioEmail }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Nombre Completo" md-sort-by="usuarioNomCompleto">{{ item.usuarioNomCompleto
                }}</md-table-cell>
              <md-table-cell md-label="Celular" md-sort-by="usuarioCelular">{{
                item.usuarioCelular
                }}</md-table-cell>

              <md-table-cell md-label="Telefono" md-sort-by="usuarioTelefono">{{
                item.usuarioTelefono
                }}</md-table-cell>
              <md-table-cell md-label="Es el responsable" md-sort-by="isResponsanle" style="width: 50px">{{
                item.isResponsanle }}</md-table-cell>
              <md-table-cell md-label="Representación" md-sort-by="representacionNombre">{{ item.representacionNombre
                }}</md-table-cell>
              <md-table-cell md-label="Activo" md-sort-by="activo" style="width: 50px">{{
                item.activo
                }}</md-table-cell>
              <md-table-cell md-label="Acciones" style="width: 150px">
                <md-button style="width: 50px" class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button style="width: 50px" class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)">
                  <md-icon>close</md-icon>
                </md-button>
                <md-button style="width: 50px" class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(4, item)">
                  <md-icon>image</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de la tabla</caption>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body" v-if="datosModal.evento == 1 || datosModal.evento == 2">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Id</label>
                <md-input v-model="UsuariosId" disabled></md-input>
                <span class="md-helper-text">Id</span> </md-field><md-field :class="UsuarioEmailError">
                <label>E-mail</label>
                <md-input v-model="UsuarioEmail" type="email"></md-input>
                <span class="md-helper-text">E-mail</span>
              </md-field>
              <md-field :class="UsuarioNombreError">
                <label>Nombre (s)</label>
                <md-input v-model="UsuarioNombre"></md-input>
                <span class="md-helper-text">Nombre (s)</span>
              </md-field>
              <md-field :class="UsuarioApellidoPaternoError">
                <label>Apellido Paterno</label>
                <md-input v-model="UsuarioApellidoPaterno"></md-input>
                <span class="md-helper-text">Apellido Paterno</span>
              </md-field>
              <md-field :class="UsuarioApellidoMaternoError">
                <label>Apellido Materno</label>
                <md-input v-model="UsuarioApellidoMaterno"></md-input>
                <span class="md-helper-text">Apellido Materno</span>
              </md-field>
              <md-field :class="UsuarioTelefonoError">
                <label>Teléfono</label>
                <md-input v-model="UsuarioTelefono"></md-input>
                <span class="md-helper-text">Usuario Teléfono</span>
              </md-field>
              <md-field :class="UsuarioCelularError">
                <label>Celular</label>
                <md-input v-model="UsuarioCelular"></md-input>
                <span class="md-helper-text">Celular</span>
              </md-field>
              <md-field :class="UsuarioInmobiliariaError">
                <label>Inmobiliaria</label>
                <md-input v-model="UsuarioInmobiliaria"></md-input>
                <span class="md-helper-text">Inmobiliaria</span>
              </md-field>
              <md-field :class="FechaNacimientoError">
                <label>Fecha de Nacimiento.</label>
                <md-input type="date" clearable placeholder="Fecha Nacimiento" v-model="FechaNacimiento"></md-input>
                <span class="md-helper-text">Fecha de Nacimiento</span>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="AreaIdError">
                <label for="AreaId">(Seleccionar área)</label>
                <md-select v-model="AreaId">
                  <md-option v-for="a in Areas" :key="a.areaId" :value="a.areaId">
                    {{ a.areaDescripcion }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field :class="RepresentacionIdError">
                <label for="RepresentacionId">(Seleccionar representacion)</label>
                <md-select v-model="RepresentacionId">
                  <md-option v-for="r in RepresentacionesData" :key="r.representacionId" :value="r.representacionId">
                    {{ r.representacionNombre }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field :class="IsResponsanleError">
                <md-checkbox v-model="IsResponsanle">IsResponsanle</md-checkbox>
              </md-field>
              <md-field :class="UsuarioPadreIdError">
                <label for="UsuarioPadreId">(Seleccionar usuario padre)</label>
                <md-select v-model="UsuarioPadreId">
                  <md-option v-for="u in tableData" :key="u.usuariosId" :value="u.usuariosId">
                    {{ u.usuarioNomCompleto }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field :class="ActivoError">
                <md-checkbox v-model="Activo">Activo</md-checkbox>
              </md-field>
              <md-field :class="UsuarioInmobiliariaError">
                <label>Titulo</label>
                <md-input v-model="titulo"></md-input>
                <span class="md-helper-text">Titulo</span>
              </md-field>
              <md-field :class="UsuarioContraseniaError">
                <label>Contraseña del sistema anterior</label>
                <md-input v-model="UsuarioContrasenia" type="password"></md-input>
                <span class="md-helper-text">Contraseña del sistema anterior</span>
              </md-field>
              <md-field :class="ContraEncryptError">
                <label>Contraseña</label>
                <md-input v-model="ContraEncrypt" type="password"></md-input>
                <span class="md-helper-text">Contraseña</span>
              </md-field>
              <md-field>
                <label>Repetir Contraseña</label>
                <md-input v-model="ContraseniaValidacion" type="password"></md-input>
                <span class="md-helper-text">Repetir Contraseña</span>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="body" v-if="datosModal.evento == 4">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <div class="file-input">
                  <div v-if="!imageRegular">
                    <div class="image-container">
                      <img :src="regularImg" alt="Descripción de la primera imagen" title="Imagen Regular" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="imageRegular" alt="Descripción de la segunda imagen" title="Imagen Regular" />
                  </div>
                  <div class="button-container">
                    <md-button class="md-danger md-round" @click="removeImage" v-if="imageRegular"><i
                        class="fa fa-times"></i>Eliminar</md-button>
                    <md-button class="md-success md-round md-fileinput">
                      <template v-if="!imageRegular">Select image</template>
                      <template v-else>Cambiar</template>
                      <input type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                </div>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-primary" @click.native="createOrUpdateOrDelete(datosModal.evento)"
            :disabled="isActivo">{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import servusuario from "../../../Servicios/Usuarios/usuario";
import store from "../../../Store/index";
import mensaje from "../../../Servicios/ManjeadorErrores/Mensajes";
import servarea from "../../../Servicios/Area/area";
import servrepresentacion from "../../../Servicios/Representacion/representacion";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },

  },
  data() {

    return {
      currentSort: "usuariosId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id",
        "Email",
        "Nombre Completo",
        "Celular",
        "Telefono",
        "Es el responsable",
        "Representacion",
        "Activo",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: ["usurioEmail", "usuarioNomCompleto"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      user: null,
      classicModal: false,
      categorias: [],
      categoriaId: 0,
      Id: 0,
      polizaid: 0,
      datosModal: {
        titulo: "Crear un usuario",
        boton: "Confirmar",
        evento: 0,
      },
      UsuariosId: 0,
      UsuarioEmail: null,
      UsuarioNombre: null,
      UsuarioApellidoPaterno: null,
      UsuarioApellidoMaterno: null,
      UsuarioTelefono: null,
      UsuarioContrasenia: null,
      UsuarioNomCompleto: null,
      UsuarioInmobiliaria: null,
      AreaId: null,
      RepresentacionId: null,
      RepresentacionNombre: null,
      UsuarioCelular: null,
      IsResponsanle: false,
      UsuarioPadreId: null,
      Activo: false,
      ContraEncrypt: null,
      UsuarioEmailError: null,
      UsuarioNombreError: null,
      UsuarioApellidoPaternoError: null,
      UsuarioApellidoMaternoError: null,
      UsuarioTelefonoError: null,
      UsuarioContraseniaError: null,
      UsuarioNomCompletoError: null,
      UsuarioInmobiliariaError: null,
      AreaIdError: null,
      RepresentacionIdError: null,
      RepresentacionNombreError: null,
      UsuarioCelularError: null,
      IsResponsanleError: false,
      UsuarioPadreIdError: null,
      ActivoError: false,
      ContraEncryptError: null,
      Areas: [],
      RepresentacionesData: [],
      ContraseniaValidacion: "",
      isLoader: true,
      isActivo: false,
      titulo: null,
      FiltroAreaId: 3, //Representaciones
      imageRegular: "",
      imagen: null,
      FechaNacimiento: null,
    };
  },
  methods: {

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    createOrUpdateOrDelete(exp) {
      this.isActivo = true;
      var isBAndera = false;
      if (
        this.ContraEncrypt != this.ContraseniaValidacion &&
        (exp == 1 || exp == 2)
      ) {
        let mens = {
          tipo: "danger",
          mensaje:
            "Las contraseñas deben de coincidir, favor de intentarlo nuevamente",
        };
        isBAndera = true;
        this.isActivo = false;
        this.notifyVue(mens);

      }
      if (this.UsuarioEmail == null && (exp == 1 || exp == 2)) {

        isBAndera = true;
        this.isActivo = false;
        let mens = {
          tipo: "danger",
          mensaje: "El email, no puede estar vacío",
        };
        this.notifyVue(mens);
      }
      if (
        this.imagen == null &&
        (exp == 4)
      ) {
        let mens = {
          tipo: "danger",
          mensaje:
            "Favor de seleccionar una imagen.",
        };
        isBAndera = true;
        this.isActivo = false;
        this.notifyVue(mens);

      }
      if (isBAndera == false) {
        var NomCompleto =
          this.UsuarioApellidoMaterno == null
            ? this.UsuarioNombre + " " + this.UsuarioApellidoPaterno
            : this.UsuarioNombre +
            " " +
            this.UsuarioApellidoPaterno +
            " " +
            this.UsuarioApellidoMaterno;

        var data = {
          usuariosId: this.UsuariosId,
          usurioEmail: this.UsuarioEmail,
          usuarioNombre: this.UsuarioNombre,
          usuarioApellidoPaterno: this.UsuarioApellidoPaterno,
          usuarioApellidoMaterno: this.UsuarioApellidoMaterno,
          usuarioTelefono: this.UsuarioTelefono,
          usuarioInmobiliaria: this.UsuarioInmobiliaria,
          areaId: this.AreaId > 0 ? this.AreaId : 0,
          representacionId: this.RepresentacionId > 0 ? this.RepresentacionId : 0,
          usuarioCelular: this.UsuarioCelular,
          isResponsanle: this.IsResponsanle,
          usuarioPadreId: this.UsuarioPadreId > 0 ? this.UsuarioPadreId : null,
          activo: this.Activo,
          contraEncrypt: btoa(this.ContraEncrypt),
          usuarioNomCompleto: NomCompleto,
          titulo: this.titulo,
          imagen: this.imagen,
          usuarioContrasenia: this.UsuarioContrasenia,
          FechaNacimiento: this.FechaNacimiento
        };
      }
      switch (exp) {
        case 1:
          if (isBAndera == false) {
            data.usuariosId = 0;
            servusuario
              .post(data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error("usuario-crud-servusuario-post", e);
              });
          }

          break;
        case 2:
        case 4:
          if (this.UsuariosId > 0 && isBAndera == false) {
            console.log(data);
            servusuario
              .put(this.UsuariosId, data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error("usuario-crud-servusuario-put", e);
              });
          }

          break;
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.tableData = [];
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      this.isLoader = true;
      servusuario
        .all(this.FiltroAreaId)
        .then((p) => {
          this.tableData = p.data;
          console.log(this.tableData);
          this.isLoader = false;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["usurioEmail", "usuarioNomCompleto"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
          this.isLoader = false;
        });
    },
    getDatosArea() {
      servarea
        .all()
        .then((a) => {
          this.Areas = a.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });
    },
    getDatosRepresentacion() {
      servrepresentacion
        .all()
        .then((r) => {
          this.RepresentacionesData = r.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-representaciones-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear un usuario";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          console.log(row);
          this.UsuariosId = row.usuariosId;
          this.UsuarioEmail = row.usurioEmail;
          this.UsuarioNombre = row.usuarioNombre;
          this.UsuarioApellidoPaterno = row.usuarioApellidoPaterno;
          this.UsuarioApellidoMaterno = row.usuarioApellidoMaterno;
          this.UsuarioTelefono = row.usuarioTelefono;
          this.UsuarioNomCompleto = row.usuarioNomCompleto
          this.UsuarioInmobiliaria = row.usuarioInmobiliaria;
          this.AreaId = row.areaId;
          this.RepresentacionId = row.representacionId;
          this.UsuarioCelular = row.usuarioCelular;
          this.IsResponsanle = row.isResponsanle;
          this.UsuarioPadreId = row.usuarioPadreId;
          this.Activo = row.activo;
          this.titulo = row.titulo;
          this.imagen = row.imagen;
          this.UsuarioContrasenia = row.usuarioContrasenia;
          this.ContraEncrypt = atob(row.contraEncrypt);
          this.ContraseniaValidacion = this.ContraEncrypt;
          let fechaNac = new Date(row.fechaNacimiento);
          console.log(fechaNac);
          //Pide formato YYYY-MM-DD, el LocaleDatestring que tiene ese formato es el de suecia sv-SE
          this.FechaNacimiento = fechaNac.toLocaleDateString("sv-SE", { month: "2-digit", day: "2-digit", year: "numeric" });
          //console.log("FechaNacimiento despues: ", this.FechaNacimiento);
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar un usuario";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.id = row.usuariosId;
          this.deleteRow(this.id);
          break;

        case 4: //imagen
          this.UsuariosId = row.usuariosId;
          this.UsuarioEmail = row.usurioEmail;
          this.UsuarioNombre = row.usuarioNombre;
          this.UsuarioApellidoPaterno = row.usuarioApellidoPaterno;
          this.UsuarioApellidoMaterno = row.usuarioApellidoMaterno;
          this.UsuarioTelefono = row.usuarioTelefono;
          this.UsuarioNomCompleto = row.usuarioNomCompleto;
          this.UsuarioInmobiliaria = row.usuarioInmobiliaria;
          this.AreaId = row.areaId;
          this.RepresentacionId = row.representacionId;
          this.UsuarioCelular = row.usuarioCelular;
          this.IsResponsanle = row.isResponsanle;
          this.UsuarioPadreId = row.usuarioPadreId;
          this.Activo = row.activo;
          this.titulo = row.titulo;
          this.imagen = row.imagen;
          this.imageRegular = row.imagen;
          this.UsuarioContrasenia = row.usuarioContrasenia;
          this.ContraEncrypt = atob(row.contraEncrypt);
          this.ContraseniaValidacion = this.ContraEncrypt;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar imagen";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 4;
          let fechaNac2 = new Date(row.fechaNacimiento);
          //Pide formato YYYY-MM-DD, el LocaleDatestring que tiene ese formato es el de suecia sv-SE
          this.FechaNacimiento = fechaNac2.toLocaleDateString("sv-SE", { month: "2-digit", day: "2-digit", year: "numeric" });
          break;
      }
    },
    deleteRow(id) {
      if (this.id > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.id} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servusuario
                .delete(this.id)
                .then((response) => {
                  this.getDatos();
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.id}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.UsuariosId = 0;
      this.UsuarioEmail = null;
      this.UsuarioNombre = null;
      this.UsuarioApellidoPaterno = null;
      this.UsuarioApellidoMaterno = null;
      this.UsuarioTelefono = null;
      this.UsuarioContrasenia = null;
      this.UsuarioNomCompleto = null;
      this.UsuarioInmobiliaria = null;
      this.AreaId = null;
      this.RepresentacionId = null;
      this.UsuarioCelular = null;
      this.IsResponsanle = false;
      this.UsuarioPadreId = null;
      this.Activo = false;
      this.ContraEncrypt = null;
      this.isActivo = false;
      this.ContraseniaValidacion = null;
      this.titulo = null;
      this.imagen = null;
      this.FechaNacimiento = null;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.createImage(files[0], "circle");
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
        this.imagen = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }

    },
  },
  created() {

    this.getDatos();
    this.getDatosArea();
    this.getDatosRepresentacion();
  },
  mounted() { },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {

        result = this.fuseSearch.search(this.searchQuery);

      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.multi-line-cell {
  white-space: pre-line;
  /* Permite saltos de línea */
  word-break: break-word;
  /* Permite que las palabras se dividan si son demasiado largas */
}
</style>
