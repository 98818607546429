<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">Fiadores (P. Fisica)</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)" 
                style="float: right;"> Nuevo </md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell 
              md-label="ID" 
              md-sort-by="FiadorFid">
              {{item.fiadorFid}}
              </md-table-cell>
              <md-table-cell
                md-label="Nombre"
                md-sort-by="FiadorFNombres"
                >{{ item.fiadorFnombres}}
              </md-table-cell>
              <md-table-cell md-label="Apellido Paterno" md-sort-by="FiadorFapePaterno">{{
                item.fiadorFapePaterno
              }}</md-table-cell>
               <md-table-cell md-label="Apellido Materno" md-sort-by="FiadorFapeMaterno">{{
                item.fiadorFapeMaterno
              }}</md-table-cell>
              <md-table-cell md-label="ACCIONES">
                <md-button
                  class="md-just-icon md-warning md-simple" 
                  @click.native="ModalPersonalizado(2, item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)">
                  <md-icon>close</md-icon>
                </md-button> 
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <!--  -->
        <template slot="body">
          <tabs :tab-name="['GENERAL', 'DOMICILIO PARTICULAR', 'DOMICILIO GARANTÍA', 'CONYUGUE', 'ESCRITURA']"
            color-button="primary">
            <template slot="tab-pane-1">
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Nombre </label>
                      <label style="color: brown !important;" v-if="isBandera">Nombre</label>
                      <md-input v-model="FiadorFNombres" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Apellido Paterno </label>
                      <label style="color: brown !important;" v-if="isBandera">Apellido Paterno</label>
                      <md-input v-model="FiadorFApePaterno" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Apellido Materno </label>
                      <label style="color: brown !important;" v-if="isBandera">Apellido Materno</label>
                      <md-input v-model="FiadorFApeMaterno"  type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Nacionalidad </label>
                      <label style="color: brown !important;" v-if="isBandera">Nacionalidad</label>
                      <md-input v-model="FiadorFNacionalidad" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Condicion migratoria </label>
                      <md-input v-model="FiadorFCondicionMigratoria"></md-input>
                      <span class="md-helper-text">Condicion migratoria</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Estado Civil </label>
                      <md-input v-model="FiadorFEstadoCivil"></md-input>
                      <span class="md-helper-text"> Estado Civil </span>
                    </md-field>

                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Email </label>
                      <label style="color: brown !important;" v-if="isBandera">Email</label>
                      <md-input v-model="FiadorFEmail" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Telefono </label>
                      <label style="color: brown !important;" v-if="isBandera">Telefono</label>
                      <md-input v-model="FiadorFTelefono" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label v-if="!isBandera"> Celular </label>
                      <label style="color: brown !important;" v-if="isBandera">Celular</label>
                      <md-input v-model="FiadorFCelular" type="text" required></md-input>
                      <span class="md-error">El campo es requerido</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Parentesco Arrendatario </label>
                      <md-input v-model="FiadorFParentesco"></md-input>
                      <span class="md-helper-text">Parentesco</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Empresa donde labora </label>
                      <md-input v-model="FiadorFEmpresa"></md-input>
                      <span class="md-helper-text">Empresa donde labora</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Telefono de la empresa </label>
                      <md-input v-model="FiadorFTelefonoEmpresa"></md-input>
                      <span class="md-helper-text">Telefono empresa</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Profesion </label>
                      <md-input v-model="FiadorFProfesion"></md-input>
                      <span class="md-helper-text">Profesion</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Tipo de Identificación </label>
                      <md-input v-model="TipoIdentificacion"></md-input>
                      <span class="md-helper-text">Tipo de Identificación</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label> Número de identificacion </label>
                      <md-input v-model="NumeroIdentificacion"></md-input>
                      <span class="md-helper-text">Número identificacion</span>
                    </md-field>
                  </div>
                </div>
              </div>
            </template>
            <template slot="tab-pane-2">
              <div class="md-layout">
                <md-field>
                  <label v-if="!isBandera"> Domicilio</label>
                  <label style="color: brown !important;" v-if="isBandera">Domicilio</label>
                  <md-input v-model="FiadorFDomicilio" type="text" required></md-input>
                  <span class="md-error">El campo es requerido</span>
                </md-field>
                <md-field>
                  <label v-if="!isBandera"> Colonia </label>
                  <label style="color: brown !important;" v-if="isBandera">Colonia</label>
                  <md-input v-model="FiadorFColonia" type="text" required></md-input>
                  <span class="md-error">El campo es requerido</span>
                </md-field>
                <md-field>
                  <label v-if="!isBandera"> Municipio ó Alcaldia </label>
                  <label style="color: brown !important;" v-if="isBandera">Municipio ó Alcaldia</label>
                  <md-input v-model="FiadorFDelegacion" type="text" required></md-input>
                  <span class="md-error">El campo es requerido</span>
                </md-field>
                <md-field>
                  <label v-if="!isBandera"> Estado </label>
                  <label style="color: brown !important;" v-if="isBandera">Estado</label>
                  <md-input v-model="FiadorFEstado" type="text" required></md-input>
                  <span class="md-error">El campo es requerido</span>
                </md-field>
                <md-field>
                  <label v-if="!isBandera"> Código Postal </label>
                  <label style="color: brown !important;" v-if="isBandera">Código Postal</label>
                  <md-input v-model="FiadorFCodigoPostal" type="text" required></md-input>
                  <span class="md-error">El campo es requerido</span>
                </md-field>
              </div>
            </template>
            <template slot="tab-pane-3">
              <div class="md-layout">
                <template>
                  <md-field>
                    <label for="tipoInmueble">Tipo de inmueble</label>
                    <md-select v-model="tipoInmueble" name="tipoInmueble" :disabled="this.modo == 5 ? true : false">
                      <md-option v-for="tipo in tinmueble" :key="tipo.tipoInmobiliarioId"
                        :value="tipo.tipoInmobiliarioId">{{ tipo.tipoInmobiliarioDesc }}</md-option>
                    </md-select>
                  </md-field>
                </template>
                <md-field>
                  <label> Domicilio</label>
                  <md-input v-model="FiadorFDomicilioGarantia"></md-input>
                  <span class="md-helper-text">Domicilio</span>
                </md-field>
                <md-field>
                  <label> Colonia </label>
                  <md-input v-model="FiadorFColoniaGarantia"></md-input>
                  <span class="md-helper-text">Colonia</span>
                </md-field>
                <md-field>
                  <label> Municipio ó Alcaldia </label>
                  <md-input v-model="FiadorFDelegacionGarantia"></md-input>
                  <span class="md-helper-text">Municipio ó Alcaldia</span>
                </md-field>
                <md-field>
                  <label> Estado </label>
                  <md-input v-model="FiadorFEstadoGarantia"></md-input>
                  <span class="md-helper-text">Estado</span>
                </md-field>
                <md-field>
                  <label> Código Postal </label>
                  <md-input v-model="FiadorFCodigoPostalGarantia"></md-input>
                  <span class="md-helper-text">Código Postal</span>
                </md-field>
                <md-button class="md-primary" style="float: right;" 
                @click="copiarDomicilioParticular()"> COPIAR DOMICILIO PARTICULAR </md-button>
              </div>
            </template>
            <template slot="tab-pane-4">
              <div class="md-layout">
                <md-field>
                  <label> Nombre</label>
                  <md-input v-model="FiadorFNombresConyuge"></md-input>
                  <span class="md-helper-text">Nombre del conyugue</span>
                </md-field>
                <md-field>
                  <label> Apellido Paterno</label>
                  <md-input v-model="FiadorFApePaternoConyuge"></md-input>
                  <span class="md-helper-text">Apellido Paterno del conyugue</span>
                </md-field>
                <md-field>
                  <label> Apellido Materno</label>
                  <md-input v-model="FiadorFApeMaternoConyuge"></md-input>
                  <span class="md-helper-text">Apellido Materno del conyugue</span>
                </md-field>
              </div>
            </template>
            <template slot="tab-pane-5">
              <div class="md-layout">
                <md-field>
                  <label> Número de Escritura </label>
                  <md-input v-model="EscrituraNumero"></md-input>
                  <span class="md-helper-text">Número de Escritura</span>
                </md-field>
                <md-field>
                  <label> Licenciado Notaria </label>
                  <md-input v-model="Licenciado"></md-input>
                  <span class="md-helper-text">Licenciado Notaria</span>
                </md-field>
                <md-field>
                  <label> Número de Notaria </label>
                  <md-input v-model="NumeroNotaria"></md-input>
                  <span class="md-helper-text">Número de Notaria</span>
                </md-field>
                <md-field>
                  <label> Distrito Judicial </label>
                  <md-input v-model="DistritoJudicial"></md-input>
                  <span class="md-helper-text">Distrito Judicial</span>
                </md-field>
                <md-field>
                  <label> Número de Partida </label>
                  <md-input v-model="PartidaNumero"></md-input>
                  <span class="md-helper-text">Número de Partida</span>
                </md-field>
                <md-field>
                  <label> Volumen </label>
                  <md-input v-model="PartidaVolumen"></md-input>
                  <span class="md-helper-text">Volumen</span>
                </md-field>
                <md-field>
                  <label> Libro </label>
                  <md-input v-model="PartidaLibro"></md-input>
                  <span class="md-helper-text">Libro</span>
                </md-field>
                <md-field>
                  <label> Sección </label>
                  <md-input v-model="PartidaSeccion"></md-input>
                  <span class="md-helper-text">Sección</span>
                </md-field>
                <md-field>
                  <label>Fecha Firma</label>
                  <md-input type="datetime-local" v-model="PartidaFecha"
                  :disabled="this.modo == 5 ? true : false">
                  </md-input>
                </md-field>
              </div>
            </template>
          </tabs>
        </template>
        <template slot="footer">
          <md-button  class="md-danger md-simple" 
          @click="classicModalHide">CANCELAR</md-button>
          <md-button v-if="this.visible == false" class="md-primary" @click.native="postNow(datosModal.evento)">{{ datosModal.boton }}</md-button>
          <md-button v-if="this.visible == true" class="md-primary" disabled>
                <div class="loader"></div>
          </md-button>
        </template>
      </modal>

    </div>
  </div>
</template>
  
<script>
import { Pagination, Modal, Tabs } from "@/components";
import servTipoInm from "@/Servicios/Solicitudes/tipoInmueble.js";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servFiadorF from "@/Servicios/Operacion/fiadorF";
import servEstados from "@/Servicios/Solicitudes/estados.js";
import store from "@/Store/index";

export default {
  components: {
    Modal,
    Pagination,
    Tabs
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },

  },

  data() {
    return {
      currentSort: "FiadorFId",
      isBandera:false,
      currentSortOrder: "asc",
      modo: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Nombre", "Apellido Paterno", "Apellido Materno", "Acciones"],
      searchQuery: "",
      propsToSearch: ["FiadorFNombres", "FiadorFApePaterno", "FiadorFApeMaterno"],
      tableData: [],
      searchedData: [],
      fuseSearch: "",
      classicModal: false,
      FiadorFId: 0,
      FiadorFNombres: "",
      FiadorFApePaterno: "",
      FiadorFApeMaterno: "",
      //id foreign key
      FisicaMoralId: 0,
      tipoInmueble: 0,
      //agregar todos los inputs
      FiadorFNacionalidad: "",
      FiadorFCondicionMigratoria: "",
      FiadorFParentesco: "",
      FiadorFEstadoCivil: "",
      FiadorFConvenioEC: "",
      FiadorFDomicilio: "",
      FiadorFColonia: "",
      FiadorFDelegacion: "",
      FiadorFEstado: "",
      FiadorFCodigoPostal: "",
      FiadorFTelefono: "",
      FiadorFCelular: "",
      FiadorFEmail: "",
      FiadorFDomicilioGarantia: "",
      FiadorFColoniaGarantia: "",
      FiadorFDelegacionGarantia: "",
      FiadorFEstadoGarantia: "",
      FiadorFProfesion: "",
      FiadorFEmpresa: "",
      FiadorFTelefonoEmpresa: "",
      FiadorFCodigoPostalGarantia: "",
      FiadorFNombresConyuge: "",
      FiadorFApePaternoConyuge: "",
      FiadorFApeMaternoConyuge: "",
      DistritoJudicial: "",
      EscrituraNumero: "",
      Licenciado: "",
      NumeroNotaria: "",
      PartidaFecha: "",
      PartidaLibro: "",
      PartidaNumero: "",
      PartidaSeccion: "",
      PartidaVolumen: "",
      TipoIdentificacion: "",
      NumeroIdentificacion: "",
      //tipoInmueble: 0,
      datosModal: {
        titulo: "Crear un fiador",
        boton: "Confirmar",
        evento: 0,
      },
      isBandera : false,
      visible: false,
    };
  },

  methods: {
    Get() {
      //cambios
      this.tableTipoInmobiliario = [];
      servRepre
        .all()
        .then((r) => {
          let todos = {
            TipoInmobiliarioId: 0,
            TipoInmobiliarioDesc: "Todas",
          };
          this.tableTipoInmobiliario = r.data; //cambio
          this.tableTipoInmobiliario.unshift(todos);
        })
        //.catch((e) => console.log(e));
    },
    obtenerestados() {
      servEstados.getEstados().then((r) => {
        this.estadosCat = r.data;
        //console.log("Estados", this.estadosCat)
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getTipoInm() {
      servTipoInm.TipoInmueble().then((data) => {
        this.tinmueble = data.data;
      });
    },
    postNow(exp) {
      this.visible = true;
      var fecha = new Date();
      var data = {
        FiadorFId: this.FiadorFId,
        FiadorFNombres: this.FiadorFNombres,
        FiadorFApePaterno: this.FiadorFApePaterno,
        FiadorFApeMaterno: this.FiadorFApeMaterno,
        //id foreing key
        FisicaMoralId: this.general.FisicaMoralId,
        TipoInmuebleId: this.tipoInmueble == 0 ? null : this.tipoInmueble,
        //agregar todos los inputs
        FiadorFNacionalidad: this.FiadorFNacionalidad,
        FiadorFCondicionMigratoria: this.FiadorFCondicionMigratoria,
        FiadorFParentesco: this.FiadorFParentesco,
        FiadorFEstadoCivil: this.FiadorFEstadoCivil,
        FiadorFConvenioEC: this.FiadorFConvenioEC,
        FiadorFDomicilio: this.FiadorFDomicilio,
        FiadorFColonia: this.FiadorFColonia,
        FiadorFDelegacion: this.FiadorFDelegacion,
        FiadorFEstado: this.FiadorFEstado,
        FiadorFCodigoPostal: this.FiadorFCodigoPostal,
        FiadorFTelefono: this.FiadorFTelefono,
        FiadorFCelular: this.FiadorFCelular,
        FiadorFEmail: this.FiadorFEmail,
        FiadorFDomicilioGarantia: this.FiadorFDomicilioGarantia,
        FiadorFColoniaGarantia: this.FiadorFColoniaGarantia,
        FiadorFDelegacionGarantia: this.FiadorFDelegacionGarantia,
        FiadorFEstadoGarantia: this.FiadorFEstadoGarantia,
        FiadorFProfesion: this.FiadorFProfesion,
        FiadorFEmpresa: this.FiadorFEmpresa,
        FiadorFTelefonoEmpresa: this.FiadorFTelefonoEmpresa,
        FiadorFCodigoPostalGarantia: this.FiadorFCodigoPostalGarantia == undefined || null ? "" : this.FiadorFCodigoPostalGarantia,
        FiadorFNombresConyuge: this.FiadorFNombresConyuge,
        FiadorFApePaternoConyuge: this.FiadorFApePaternoConyuge,
        FiadorFApeMaternoConyuge: this.FiadorFApeMaternoConyuge,
        DistritoJudicial: this.DistritoJudicial,
        EscrituraNumero: this.EscrituraNumero,
        Licenciado: this.Licenciado,
        NumeroNotaria: this.NumeroNotaria,
        PartidaFecha: this.PartidaFecha == "" || null ? fecha : this.PartidaFecha,
        PartidaLibro: this.PartidaLibro,
        PartidaNumero: this.PartidaNumero,
        PartidaSeccion: this.PartidaSeccion,
        PartidaVolumen: this.PartidaVolumen,
        TipoIdentificacion: this.TipoIdentificacion,
        NumeroIdentificacion: this.NumeroIdentificacion,
      };
      switch (exp) {
        case 1:
          servFiadorF
            .post(data)
            .then((r) => {
              //hay que cerrar el model
              //console.log(data)
              this.ProcesamosResponse(r.data)
              //Aqui recuperamos los datos
            })
            .catch(function (e) {
              let m = {
                mensaje: "",
                tipo: "",
              };
              m.mensaje = "Ocurrio un error verifica los campos en rojo"
              m.tipo = "danger"; 
              this.isBandera = true;  
              this.visible = false;  
              this.notifyVue(m);
            });
        break;

        case 2:
            servFiadorF
              .put(this.FiadorFId, data)
              .then((r) => {
                //hay que cerrar el model
                this.classicModal = true;
                this.ProcesamosResponse(r.data)
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("fiadorf-crud-put", e);
              });
          
        break;
      }
    },
    getDatos() {
      servFiadorF
        .getId(this.general.FisicaMoralId)
        .then((a) => {
          this.tableData = a.data;
          // console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["FiadorFId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("fiador-fiadorf-all", e);
        });
    },
    Asignamos(r) {
      this.tipoInmobiliarioId = r.tipoInmobiliarioId;
      //console.log("tipo inmueble: " + this.tipoInmobiliarioId);
      this.tinmueble = r.tinmueble;
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = " Nuevo ";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          this.FiadorFId = 0;
          break;
          case 2: //Actualizar
          this.FiadorFId=row.fiadorFid;
          this.FiadorFNombres = row.fiadorFnombres;
          this.FiadorFApePaterno = row.fiadorFapePaterno;
          this.FiadorFApeMaterno = row.fiadorFapeMaterno;
          //foreing key
          this.tipoInmueble= row.tipoInmuebleId;
          this.FisicaMoralId = row.fisicaMoralId;
          //Agregar todos los input.
          this.FiadorFNacionalidad = row.fiadorFnacionalidad;
          this.FiadorFCondicionMigratoria = row.fiadorFcondicionMigratoria;
          this.FiadorFParentesco = row.fiadorFparentesco;
          this.FiadorFEstadoCivil = row.fiadorFestadoCivil;
          this.FiadorFConvenioEC = row.fiadorFconvenioEC;
          this.FiadorFDomicilio = row.fiadorFdomicilio;
          this.FiadorFColonia = row.fiadorFcolonia;
          this.FiadorFDelegacion = row.fiadorFdelegacion;
          this.FiadorFEstado = row.fiadorFestado;
          this.FiadorFCodigoPostal = row.fiadorFcodigoPostal;
          this.FiadorFTelefono = row.fiadorFtelefono;
          this.FiadorFCelular = row.fiadorFcelular;
          this.FiadorFEmail = row.fiadorFemail;
          this.FiadorFDomicilioGarantia = row.fiadorFdomicilioGarantia;
          this.FiadorFColoniaGarantia = row.fiadorFcoloniaGarantia;
          this.FiadorFDelegacionGarantia = row.fiadorFdelegacionGarantia;
          this.FiadorFEstadoGarantia = row.fiadorFestadoGarantia;
          this.FiadorFProfesion = row.fiadorFprofesion;
          this.FiadorFEmpresa = row.fiadorFempresa;
          this.FiadorFTelefonoEmpresa = row.fiadorFtelefonoEmpresa;
          this.FiadorFCodigoPostalGarantia = row.fiadorFcodigoPostalGarantia;
          this.FiadorFNombresConyuge = row.fiadorFnombresConyuge;
          this.FiadorFApePaternoConyuge = row.fiadorFapePaternoConyuge;
          this.FiadorFApeMaternoConyuge = row.fiadorFapeMaternoConyuge;
          this.DistritoJudicial = row.distritoJudicial;
          this.EscrituraNumero = row.escrituraNumero;
          this.Licenciado = row.licenciado;
          this.NumeroNotaria = row.numeroNotaria;
          this.PartidaFecha = row.partidaFecha;
          this.PartidaLibro = row.partidaLibro;
          this.PartidaNumero = row.partidaNumero;
          this.PartidaSeccion = row.partidaSeccion;
          this.PartidaVolumen = row.partidaVolumen;
          this.TipoIdentificacion= row.tipoIdentificacion;
          this.NumeroIdentificacion = row.numeroIdentificacion;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar fiador";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.fiadorFid = row.fiadorFid;
          this.deleteRow(this.fiadorFid);
          break;
      }
    },

    deleteRow() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        buttonsStyling: false,
      });
      if (this.fiadorFid > 0) {
        swalWithBootstrapButtons.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.fiadorFid} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servFiadorF
                .delete(this.fiadorFid)
                .then((response) => {
                  swalWithBootstrapButtons.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.fiadorFid}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    ProcesamosResponse(data) {
      //console.debug("datafiadorf",data)
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.isBandera= true;
            this.visible = false;
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.isBandera= true;
            this.visible = false;
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.isBandera= false;
            this.visible = false;
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    Clear() {
      this.isBandera= false;
      this.FiadorFid = 0;
      this.FiadorFNombres = "";
      this.FiadorFApePaterno = "";
      this.FiadorFApeMaterno = "";
      //id foreing key
      this.FisicaMoralId = 0;
      this.tipoInmueble = 0;
      //agregar todos los input
      this.FiadorFNacionalidad = "";
      this.FiadorFCondicionMigratoria = "";
      this.FiadorFParentesco = "";
      this.FiadorFEstadoCivil = "";
      this.FiadorFConvenioEC = "";
      this.FiadorFDomicilio = "";
      this.FiadorFColonia = "";
      this.FiadorFDelegacion = "";
      this.FiadorFEstado = "";
      this.FiadorFCodigoPostal = "";
      this.FiadorFTelefono = "";
      this.FiadorFCelular = "";
      this.FiadorFEmail = "";
      this.FiadorFDomicilioGarantia = "";
      this.FiadorFColoniaGarantia = "";
      this.FiadorFDelegacionGarantia = "";
      this.FiadorFEstadoGarantia = "";
      this.FiadorFProfesion = "";  
      this.FiadorFEmpresa = "";
      this.FiadorFTelefonoEmpresa = "";
      this.FiadorFCodigoPostalGarantia = "";
      this.FiadorFNombresConyuge = "";
      this.FiadorFApePaternoConyuge = "";
      this.FiadorFApeMaternoConyuge = "";
      this.DistritoJudicial = "";
      this.EscrituraNumero = "";
      this.Licenciado = "";
      this.NumeroNotaria = "";
      this.PartidaFecha = "";
      this.PartidaLibro = "";
      this.PartidaNumero = "";
      this.PartidaSeccion = "";
      this.PartidaVolumen = "";
      this.TipoIdentificacion = "";
      this.NumeroIdentificacion = "";
      this.visible = false;
    },
    copiarDomicilioParticular(){
      this.FiadorFDomicilioGarantia = this.FiadorFDomicilio;
      this.FiadorFColoniaGarantia = this.FiadorFColonia;
      this.FiadorFDelegacionGarantia = this.FiadorFDelegacion;
      this.FiadorFEstadoGarantia = this.FiadorFEstado;
      this.FiadorFCodigoPostalGarantia = this.FiadorFCodigoPostal;
    }
  },
  created() {
    //console.debug("fisicamoral-fisicomoralid",this.general.FisicaMoralId);
    //console.log("Este es TableData", this.tableData)
    //console.log("FISICAMORALID", this.general.FisicaMoralId)
    this.getTipoInm();
   
    this.getDatos();
      this.FisicaMoralId = this.general.FisicaMoralId;
    
    
  },

  mounted() {  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        //console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
        // console.log(result);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
  .md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Guardando....."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}
</style>

