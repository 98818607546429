<template>
  <div class="iframe-container">
    <iframe :src="iframeUrl" frameborder="0" width="100%" height="100vh" allowtransparency></iframe>
  </div>
</template>

<script>
import store from "@/Store";
import servMetabase from "@/Servicios/Dashboard/dashboard";

export default {
  name: "Metabase-Representacion",
  data() {
    return {
      iframeUrl: "",
    };
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  methods: {
    async getToken() {
      try {
        const response = await servMetabase.Metabase(this.usuario.usuariosId,this.usuario.representacionId);
        this.iframeUrl = response.data.iframeUrl;
        console.log("iframe URL:", this.iframeUrl);
      } catch (error) {
        console.error("Error al obtener el token:", error);
      }
    },
  },
  created() {
    this.getToken();
    this.$notifyVue('info', 'Bienvenid@ ' + this.usuario.usuarioNombre);
  },
};
</script>

<style scoped>
.iframe-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>